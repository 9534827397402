// src/components/FooterSection.js
import React from 'react';
import { Box, Typography, IconButton, Link } from '@mui/material';
import { FaFacebookF, FaInstagram, FaYoutube, FaTiktok, FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import ZillowIcon from './ZillowIcon';

const FooterSection = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#f0f0f0', // Lighter background for contrast
        color: '#333',
        padding: '40px 0',
        marginTop: 'auto',
      }}
    >
      <Box
        sx={{
          maxWidth: '1200px',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          padding: '0 20px',
        }}
      >
        {/* Contact Information */}
        <Box sx={{ marginBottom: '20px', textAlign: 'center' }}>
          <Typography variant="h6" sx={{ marginBottom: '10px' }}>
            Contact Information
          </Typography>
          <Typography variant="body1">
            <FaPhone /> (248) 989-5737, (248) 609-8000
          </Typography>
          <Typography variant="body1">
            <FaEnvelope /> <Link href="mailto:ganesh.alpine3@gmail.com" sx={{ color: '#333', textDecoration: 'none' }}>ganesh.alpine3@gmail.com</Link>
          </Typography>
          <Typography variant="body1">
            <FaMapMarkerAlt /> 440 S Main St, Rochester, MI, 48307
          </Typography>
        </Box>

        {/* Social Media Heading */}
        <Typography variant="h6" sx={{ marginBottom: '20px', color: '#333' }}>
          Find me on social media
        </Typography>

        {/* Social Media Icons */}
        <Box sx={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
          <IconButton
            component={Link}
            href="https://www.facebook.com/GaneshalpineRealtor/"
            target="_blank"
            rel="noopener"
            aria-label="Facebook"
            sx={{ color: '#4267B2', fontSize: '2rem' }}
          >
            <FaFacebookF size={40} />
          </IconButton>
          <IconButton
            component={Link}
            href="https://www.instagram.com/realtorganeshalpine?igsh=ZTN4MmJiOGVlcDd6"
            target="_blank"
            rel="noopener"
            aria-label="Instagram"
            sx={{ color: '#E4405F', fontSize: '2rem' }}
          >
            <FaInstagram size={40} />
          </IconButton>
          <IconButton
            component={Link}
            href="https://g.co/kgs/2DwmCsr"
            target="_blank"
            rel="noopener"
            aria-label="Google Reviews"
            sx={{ fontSize: '2rem' }}
          >
            <FcGoogle size={40} />
          </IconButton>
          <IconButton
            component={Link}
            href="https://www.youtube.com/@kellerwilliams"
            target="_blank"
            rel="noopener"
            aria-label="YouTube"
            sx={{ color: '#FF0000', fontSize: '2rem' }}
          >
            <FaYoutube size={40} />
          </IconButton>
          <IconButton
            component={Link}
            href="https://www.tiktok.com/@neupaneganeshrealtor"
            target="_blank"
            rel="noopener"
            aria-label="TikTok"
            sx={{ color: '#000000', fontSize: '2rem' }}
          >
            <FaTiktok size={40} />
          </IconButton>
          <IconButton
            component={Link}
            href="https://www.zillow.com/profile/realtorganesh"
            target="_blank"
            rel="noopener"
            aria-label="Zillow"
            sx={{ color: '#0074E4', fontSize: '2rem' }}
          >
            <ZillowIcon width={40} height={40} />
          </IconButton>
        </Box>

        {/* Copyright */}
        <Typography variant="body2" align="center" sx={{ color: '#333' }}>
          © {new Date().getFullYear()} Ganesh Neupane. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default FooterSection;