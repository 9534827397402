// src/components/TestimonialsSection.js
import React, { useState } from 'react';
import { Card, CardContent, Typography, Box, Link } from '@mui/material';
import { Star, StarBorder } from '@mui/icons-material';
import { FaFacebookF } from 'react-icons/fa'; // Importing Google and Facebook icons
import { FcGoogle } from 'react-icons/fc';
import ZillowIcon from './ZillowIcon'; // Ensure this path is correct

const testimonials = [
  {
    name: 'Ishan T',
    stars: 5,
    highlight: 'Dedicated, Knowledgeable and Reliable.',
    feedback:
      'My experience with Ganesh from KW was exceptional. Their professionalism, expertise, and ' +
      'dedication made the home-buying process seamless. From understanding my needs to providing valuable ' +
      'insights and guidance, Ganesh was there every step of the way. They tailored their search to match ' +
      'my criteria, ultimately helping me find a home that exceeded my expectations. ' +
      'Their negotiation skills were instrumental in securing a fantastic deal. What truly stood ' +
      'out was their genuine care and empathy throughout the process, providing unwavering support ' +
      'and reassurance. Overall, Ganesh is a knowledgeable, reliable, and dedicated real estate ' +
      'professional whom I highly recommend to anyone in search of their dream home. Thank you, ' +
      'Ganesh, for making my home buying journey a great success!',
  },
  {
    name: 'Shiv K',
    stars: 5,
    highlight: 'One of the best realtors.',
    feedback:
      'Ganesh is a competent, caring and friendly real estate agent. He will pay attention to your needs ' +
      'and help you get the best deal. He genuinely cares for his clients and is very approachable for any questions. ' +
      'He patiently walks you through every step of the deal. Service is prompt, professional and positive. ' +
      'I wholeheartedly recommend Ganesh to you!',
  },
  {
    name: 'Dhurba Gc',
    stars: 5,
    highlight: 'Helpful, Highly likely to recommend',
    feedback:
      'I had an amazing experience working with my real estate agent from start to finish. ' +
      ' They were incredibly supportive and guided me through the entire process. From helping '
      +'me find the perfect house to closing the deal, they were there every step of the way. '
      + 'I truly appreciate their dedication and expertise. I highly recommend them for anyone '
      + 'looking to buy or sell a property.'
  },
  {
    name: 'Kiran L',
    stars: 5,
    highlight: 'Punctual, Friendly, Knowledgeable and Caring.',
    feedback:
      'My wife and I could not have asked for a better agent to work with in purchasing our first home. ' +
      'Ganesh was impressively punctual, knowledgeable, and friendly. We truly feel like we made a friend through this ' +
      'process. He offered great insight into a variety of properties that we looked at. It was clear that his ' +
      'priority was making sure we found a home that we would be happy in. He was never impatient and was always ' +
      'understanding. If you are looking for an agent, Ganesh is the best. You will not be disappointed.',
  },
  {
    name: 'Samjhana Gyawali',
    stars: 5,
    highlight: 'Incredibly Helpful',
    feedback:
      'Thank you so much for all of your help! We are so grateful for all of the hard work you have ' +
      ' done to find our dream home Thank you for all your help!!',
  },
  {
    name: 'Bibek Kharel',
    stars: 5,
    highlight: 'Honest, Assitance, Regular Feedback',
    feedback:
      'Ganesh is very knowledgeable and has been a very good agent throughout our home buying process. '
      + 'He provided valuable information and gave us honest feedbacks so it was easier to make good '
      + ' decisions. He kept on following up and assisting even after the buying process.',
  },
];

const reviewPlatforms = [
  {
    name: 'Zillow',
    href: 'https://www.zillow.com/profile/realtorganesh',
    icon: ZillowIcon,
    alt: 'Zillow Reviews',
  },
  {
    name: 'Google',
    href: 'https://g.co/kgs/2DwmCsr',
    icon: FcGoogle,
  },
  {
    name: 'Facebook',
    href: 'https://www.facebook.com/GaneshalpineRealtor/',
    icon: FaFacebookF,
  },
  // Add more platforms if needed
];

const TestimonialsSection = () => {
  const [expandedTestimonial, setExpandedTestimonial] = useState(null);

  // Toggle expanded state of the testimonial on click
  const handleCardClick = (index) => {
    setExpandedTestimonial(expandedTestimonial === index ? null : index);
  };

  return (
    <Box sx={{ padding: '50px 20px', backgroundColor: '#f9f9f9' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Client Words
      </Typography>

      <Box
        sx={{
          display: 'flex',
          overflowX: 'auto',
          gap: 2,
          padding: '20px 0',
          whiteSpace: 'nowrap',
        }}
      >
        {testimonials.map((testimonial, index) => (
          <Card
            key={index}
            onClick={() => handleCardClick(index)}
            sx={{
              minWidth: 300,
              maxWidth: 300,
              height: expandedTestimonial === index ? 'auto' : 350,
              cursor: 'pointer',
              transition: 'height 0.3s ease',
              overflow: 'hidden',
              flex: '0 0 auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CardContent
              sx={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%', // Ensure full width
                flexGrow: 1, // Allow content to expand
              }}
            >
              <Typography variant="h6" gutterBottom>
                {testimonial.name}
              </Typography>

              {/* Star Rating */}
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                {Array.from({ length: 5 }, (_, i) =>
                  i < testimonial.stars ? (
                    <Star key={i} sx={{ color: '#FFD700' }} /> // Filled star
                  ) : (
                    <StarBorder key={i} sx={{ color: '#FFD700' }} /> // Empty star
                  )
                )}
              </Box>

              {/* Highlight Section */}
              <Typography
                variant="subtitle1"
                color="textSecondary"
                sx={{
                  fontWeight: 'bold',
                  mb: 1,
                  whiteSpace: 'normal', // Allow wrapping
                  wordBreak: 'break-word', // Break long words if necessary
                  textAlign: 'center', // Center align text
                }}
              >
                {testimonial.highlight}
              </Typography>

              {/* Feedback Section */}
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  whiteSpace: 'normal', // Allow wrapping
                  wordBreak: 'break-word', // Break long words if necessary
                  textAlign: 'left', // Align feedback text to left for better readability
                }}
              >
                {expandedTestimonial === index
                  ? testimonial.feedback
                  : `${testimonial.feedback.slice(0, 400)}...`}
              </Typography>
            </CardContent>
          </Card>
        ))}

        {/* External Reviews Card */}
        <Card
          sx={{
            minWidth: 300,
            maxWidth: 300,
            textAlign: 'center',
            flex: '0 0 auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              flexGrow: 1,
            }}
          >
            <Typography variant="h6" gutterBottom>
              See More Reviews
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                mb: 2,
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                textAlign: 'center',
              }}
            >
              Discover more reviews from my clients on Zillow, Google, and Facebook.
            </Typography>

            {/* Links to External Reviews with respective logos/icons */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column', // Stack logos vertically
                gap: 2, // Space between logos
                width: '100%',
              }}
            >
              {reviewPlatforms.map((platform, idx) => (
                <Link
                  key={idx}
                  href={platform.href}
                  target="_blank"
                  rel="noopener"
                  aria-label={`${platform.name} Reviews`}
                  sx={{ width: '100%' }}
                >
                  {platform.logo ? (
                    // For platforms with image logos (e.g., Zillow)
                    <Box
                      component="img"
                      src={platform.logo}
                      alt={platform.alt}
                      sx={{
                        width: '100%',
                        maxWidth: '200px',
                        height: 'auto',
                        objectFit: 'contain',
                        transition: 'transform 0.2s, opacity 0.2s',
                        '&:hover': {
                          transform: 'scale(1.05)',
                          opacity: 0.8,
                        },
                      }}
                    />
                  ) : (
                    // For platforms using react-icons (e.g., Google, Facebook)
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '50px', // Fixed height for consistency
                        backgroundColor: '#fff', // Optional: background color
                        borderRadius: '4px', // Optional: rounded corners
                        boxShadow: '0 1px 3px rgba(0,0,0,0.2)', // Optional: subtle shadow
                        transition: 'transform 0.2s, opacity 0.2s',
                        '&:hover': {
                          transform: 'scale(1.05)',
                          opacity: 0.8,
                        },
                      }}
                    >
                      <platform.icon size={30} color={platform.color} />
                    </Box>
                  )}
                </Link>
              ))}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default TestimonialsSection;