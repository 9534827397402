// src/components/OptionsSection.js
import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import buyingImage from './buying.jpg';
import sellingImage from './selling.jpg';
import movingImage from './moving.jpg';
import { FaHome, FaTag, FaTruckMoving } from 'react-icons/fa';

const options = [
  {
    title: 'Buying',
    description:
      'Explore a wide range of properties to find your dream home. I will walk you through '
      + ' every step of the process to get you your dream home.',
    image: buyingImage,
    icon: <FaHome size={40} color="#1d43b5" />,
  },
  {
    title: 'Selling',
    description:
      'Maximize the value of your property with my comprehensive selling services. I will '
      + 'get you the best possible value for your house.',
    image: sellingImage,
    icon: <FaTag size={40} color="#1d43b5" />,
  },
  {
    title: 'Moving',
    description:
      'Thinking of changing house? I will help you through every step of selling your current home '
      + 'and buying your new home. Changing house can be daunting. With my expertise, we can share '
      + ' the amount of effort that goes into changing your home.',
    image: movingImage,
    icon: <FaTruckMoving size={40} color="#1d43b5" />,
  },
  {
    title: 'Investment Property',
    description:
      'I can help you find a house to rent or help you rent out your investment property. ',
    image: buyingImage,
    icon: <FaHome size={40} color="#1d43b5" />,
  },
];

const OptionsSection = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column', // Arrange heading and cards vertically
      alignItems: 'center', // Center align the heading
      padding: '40px 20px', // Increased top padding for spacing
      backgroundColor: '#f5f5f5', // Optional: Light background color for contrast
    }}
  >
    {/* Heading Section */}
    <Typography
      variant="h4"
      component="h2"
      gutterBottom
      sx={{
        color: '#333333',
        textAlign: 'center',
        marginBottom: '40px', // Space between heading and cards
      }}
    >
      Services Provided
    </Typography>

    {/* Cards Container */}
    <Box
      sx={{
        display: 'flex',
        overflowX: 'auto',
        padding: '0 20px',
        gap: 4, // Increased gap for better spacing between cards
        // Removed justifyContent: 'center' to align cards to the start
        width: '100%',
        // Optional: Add scroll snapping for better UX
        scrollSnapType: 'x mandatory',
        '&::-webkit-scrollbar': {
          height: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#ccc',
          borderRadius: '4px',
        },
      }}
    >
      {options.map((option) => (
        <Card
          key={option.title}
          sx={{
            minWidth: { xs: 200, sm: 250, md: 300 },
            maxWidth: { xs: 200, sm: 250, md: 300 },
            flex: '0 0 auto', // Prevents cards from shrinking
            position: 'relative', // For overlay positioning
            borderRadius: 2, // Rounds the corners
            overflow: 'hidden', // Ensures content doesn't spill over
            boxShadow: 3, // Adds a subtle shadow
            transition: 'transform 0.2s, box-shadow 0.2s', // Smooth hover effects
            backgroundColor: '#ffffff', // Optional: White background for cards
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: 6,
            },
            // Pseudo-elements for background and gradient
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundImage: `url(${option.image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              filter: 'blur(8px)', // Applies blur effect
              transform: 'scale(1.1)', // Slightly scales up to cover edges post-blur
              zIndex: 1, // Places the image below the gradient and content
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.7))',
              zIndex: 2, // Places the gradient above the blurred image
            },
            // Scroll snapping alignment
            scrollSnapAlign: 'start',
          }}
        >
          {/* Icon Overlay */}
          <Box sx={{ position: 'absolute', top: 16, left: 16, zIndex: 3 }}>
            {option.icon}
          </Box>

          {/* Content Overlay */}
          <CardContent
            sx={{
              position: 'relative',
              zIndex: 4, // Places the content above the gradient
              color: '#ffffff', // White text for contrast
              textAlign: 'center',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '20px', // Adds padding inside the card
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.8rem' },
              }}
            >
              {option.title}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' },
              }}
            >
              {option.description}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  </Box>
);

export default OptionsSection;