// src/components/Navbar.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';

const Navbar = () => (
  <AppBar
    position="sticky"
    sx={{
      backgroundColor: 'rgba(255, 255, 255, 0.9)', // Light, translucent background
      color: '#333',
      boxShadow: 'none',
      fontFamily: 'Poppins, sans-serif', // Set default font for the navbar
    }}
  >
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', paddingX: '20px' }}>
      {/* Brand Name with Custom Font */}
      <Typography
        variant="h6"
        component="div"
        sx={{
          fontFamily: 'Roboto Slab, serif', // Fancy font for brand
          fontWeight: 'bold',
          fontSize: '1.5rem',
        }}
      >
        Realtor Ganesh Neupane
      </Typography>

      {/* Navbar Links */}
      <div>
        {/* Define links with specific targets */}
        {[
          { name: 'About', to: 'about' },
          { name: 'Properties', to: 'properties' },
          { name: 'Contact', to: 'contact' },
        ].map((section) => (
          <Button
            key={section.name}
            color="inherit"
            component={ScrollLink}
            to={section.to}
            smooth={true}
            duration={500}
            offset={-70} // Offset to account for fixed navbar height
            style={{ marginLeft: '10px', fontWeight: 'bold', fontFamily: 'Poppins, sans-serif' }}
          >
            {section.name}
          </Button>
        ))}
      </div>
    </Toolbar>
  </AppBar>
);

export default Navbar;