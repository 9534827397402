// src/components/PropertiesSection.js
import React from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, CardActionArea, Box } from '@mui/material';
import house1 from './sold1_Canton.png';
import sold1 from './sold1_Warren.png';
import forsale1 from './forsale1_SterlingHeights.png'; 
import forsale2 from './forsale2_SterlingHeights.png';
import sold2 from './sold2_CommerceTwnship.png';
import sold3 from './sold3_troy.png';
import seemore from './seemore.jpg';

const properties = [
  {
    title: 'Modern Family Home - Sold',
    location: 'Canton, MI',
    description: 'A beautiful family home with spacious interiors.',
    image: house1,
    zillowLink: 'https://www.zillow.com/homedetails/1427-Walnut-Ridge-Cir-Canton-MI-48187/88657183_zpid/?', // Replace with actual Zillow URL
  },
  {
    title: 'Family Home - Sold',
    location: 'Warren, MI',
    description: '',
    image: sold1,
    zillowLink: 'https://www.zillow.com/homedetails/29717-Van-Laan-Dr-Warren-MI-48092/83527022_zpid/?mmlb=g,0', // Replace with actual Zillow URL
  },
  {
    title: 'Modern Family Home - On Market',
    location: 'Sterling Heights, MI',
    description: 'A beautiful family home with spacious interiors.',
    image: forsale1,
    zillowLink: 'https://www.zillow.com/homedetails/34556-Viceroy-Dr-Sterling-Heights-MI-48310/83645491_zpid/', // Replace with actual Zillow URL
  },
  {
    title: 'Modern Family Home - On Market',
    location: 'Sterling Heights, MI',
    description: '',
    image: forsale2,
    zillowLink: 'https://www.zillow.com/homedetails/36228-Clifford-Dr-Sterling-Heights-MI-48312/83458328_zpid/', // Replace with actual Zillow URL
  },
  {
    title: 'Modern Family Home - Sold',
    location: 'Commerce Township, MI',
    description: '',
    image: sold2,
    zillowLink: 'https://www.zillow.com/homedetails/2710-Tulip-Way-Commerce-Township-MI-48382/2056363275_zpid/?', // Replace with actual Zillow URL
  },
  {
    title: 'Modern Family Home - Sold',
    location: 'Troy, MI',
    description: '',
    image: sold3,
    zillowLink: 'https://www.zillow.com/homedetails/5064-Shrewsbury-Dr-Troy-MI-48085/24514950_zpid/?', // Replace with actual Zillow URL
  },
  {
    title: 'See More..',
    location: 'More listings on zillow.',
    description: '',
    image: seemore,
    zillowLink: '  https://www.zillow.com/profile/realtorganesh', // Replace with actual Zillow URL
  },

];

const PropertiesSectionSold = () => (
  <Box sx={{ padding: '50px 20px' }}>
    <Typography variant="h4" align="center" gutterBottom>
      Recently Represented Properties
    </Typography>

    {/* Horizontal scroll container */}
    <Box
      sx={{
        display: 'flex',
        overflowX: 'auto',
        padding: '10px 0',
        '&::-webkit-scrollbar': { display: 'none' },
      }}
    >
      {/* Cards stacked two vertically */}
      {properties.map((property, index) => (
        <Box key={index} sx={{ minWidth: '300px', marginRight: 2 }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Card>
                <CardActionArea href={property.zillowLink} target="_blank" rel="noopener noreferrer">
                  <CardContent>
                    <Typography variant="subtitle1" color="textSecondary" align="center">
                      {property.location}
                    </Typography>
                  </CardContent>
                  <CardMedia
                    component="img"
                    height="200"
                    image={property.image}
                    alt={property.title}
                  />
                  <CardContent>
                    <Typography variant="h6" align="center">{property.title}</Typography>
                    <Typography variant="body2" color="textSecondary" align="center">
                      {property.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  </Box>
);

export default PropertiesSectionSold;