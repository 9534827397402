// src/components/ContactFormSection.js
import React, { useState } from 'react';
import { Grid, TextField, Button, Typography, Box, FormControlLabel, Switch } from '@mui/material';
import InputMask from 'react-input-mask';

const ContactFormSection = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });

  const [isFirstTimeBuyer, setIsFirstTimeBuyer] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleToggleChange = () => {
    setIsFirstTimeBuyer(!isFirstTimeBuyer);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { firstName, lastName, email, phone, message } = formData;
    const firstTimeBuyer = isFirstTimeBuyer ? 'Yes' : 'No';

    window.location.href = `mailto:ganeshneupane@kw.com?subject=Message from ${firstName} ${lastName}&body=Name: ${firstName} ${lastName}%0D%0AEmail: ${email}%0D%0APhone: ${phone}%0D%0AFirst Time Home Buyer: ${firstTimeBuyer}%0D%0A%0D%0A${message}`;
    
    setFormData({ firstName: '', lastName: '', email: '', phone: '', message: '' });
    setIsFirstTimeBuyer(false);
    alert('Message sent successfully!');
  };

  return (
    <Box sx={{ mt: 3, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Send me a quick email!
      </Typography>
      <Box
        component="form"
        sx={{ maxWidth: 600, margin: 'auto', backgroundColor: 'white', p: 4, borderRadius: 2, boxShadow: 3, textAlign: 'center' }}
        onSubmit={handleSubmit}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              margin="normal"
              required
              value={formData.firstName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              margin="normal"
              required
              value={formData.lastName}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <TextField
          fullWidth
          label="Your Email"
          name="email"
          type="email"
          margin="normal"
          required
          value={formData.email}
          onChange={handleChange}
          sx={{ mt: 2 }}
        />
        <InputMask
          mask="(999) 999-9999"
          value={formData.phone}
          onChange={handleChange}
        >
          {(inputProps) => (
            <TextField
              {...inputProps}
              fullWidth
              label="Your Phone Number"
              name="phone"
              type="tel"
              margin="normal"
              required
              sx={{ mt: 2 }}
            />
          )}
        </InputMask>
        <FormControlLabel
          control={<Switch checked={isFirstTimeBuyer} onChange={handleToggleChange} color="primary" />}
          label={isFirstTimeBuyer ? "Yes, I am a first-time home buyer" : "No, I am not a first-time home buyer"}
          sx={{ mt: 2, justifyContent: 'center', width: '100%' }}
        />
        <TextField
          fullWidth
          label="Your Message"
          name="message"
          multiline
          rows={4}
          margin="normal"
          required
          value={formData.message}
          onChange={handleChange}
          sx={{ mt: 2 }}
        />
        <Button variant="contained" type="submit" sx={{ mt: 3 }}>
          Send Message
        </Button>
      </Box>
    </Box>
  );
}

export default ContactFormSection;